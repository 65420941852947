@use '@angular/material' as mat;

@import "@fontsource/montserrat";
@import "@fontsource/montserrat/500.css";
@import "@fontsource/montserrat/300.css";
@import "@fontsource/montserrat/700.css";

@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/material';
@import 'material-icons/iconfont/material-icons.css';

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzAwNGQ4NyIsIj9lcjwjYjNjYWRiIiwiO2VyPCMwMDM0NmF$LCIlPmBePCM3NWI0NTgiLCI~ZXI8I2Q2ZTljZCIsIjtlcjwjNTg5YzNkfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjExfQ==
*/

// this is the important line
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.


$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, "Montserrat", -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, "Montserrat", -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, "Montserrat", 0em),
  display-1: mat-typography-level(34px, 40px, 400, "Montserrat", 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, "Montserrat", 0em),
  title: mat-typography-level(20px, 32px, 500, "Montserrat", 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, "Montserrat", 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, "Montserrat", 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, "Montserrat", 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, "Montserrat", 0.0179em),
  button: mat-typography-level(14px, 14px, 500, "Montserrat", 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, "Montserrat", 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, "Montserrat", 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #444;
$dark-primary-text: rgba($dark-text, 1);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: #444,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.legacy-core(); 
// Theme Config

body {
  --primary-color: #004d87;
  --gray-text: #8C8C8C;
  --accent: #dedc00;
  --primary-lighter-color: #b3cadb;
  --primary-darker-color: #00346a;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  --light-background: #f1f1f1;
  --dark-text: #444;
}

$mat-primary: (
  main: #004d87,
  lighter: #b3cadb,
  darker: #00346a,
  200: #004d87,
  // For slide toggle,
  contrast: (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #75b458;
  --accent-lighter-color: #d6e9cd;
  --accent-darker-color: #589c3d;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #75b458,
  lighter: #d6e9cd,
  darker: #589c3d,
  200: #75b458,
  // For slide toggle,
  contrast: (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast: (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-warn: mat.define-palette((
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast: (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
), main, lighter, darker);
$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  typography: mat.define-typography-config($font-family: "Montserrat"),

  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
));

$altTheme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  typography: mat.define-typography-config($font-family: "Montserrat"),

  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
));

// Theme Init
@include mat.all-legacy-component-themes($theme); // this is the important line

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";

  .mat-badge-content {
    font-family: "Montserrat";
  }
}

.mat-icon-gray {
  color: #ccc;

  &:hover {
    color: #999;
  }
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
}

.container {
  width: -webkit-fill-available;
}

table {
  width: 100%;
  cursor: pointer;
}

tr.mat-row {
  &:hover {
    background-color: var(--light-background);

    .mat-icon-gray {
      color: #bbb;

      &:hover {
        color: #999;
      }
    }
  }
}

.mat-column-buttons {
  min-width: 150px;
  text-align: right;
}

.mat-list-item-content {
  padding: 0 !important;
}

hr {
  border-top: 0px;
  border-bottom: 1px solid var(--accent);
  margin: .5rem 0 1rem;
  opacity: 1;
}

.mat-card-header-text {
  margin: 0 !important;
}

.mat-form-field {
  letter-spacing: 0;
}

.select-product-type {
  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-infix {
    border-top: 0;
    width: unset;
  }

  .mat-form-field-underline {
    display: none;
  }
}

.mat-cell,
.mat-header-cell {
  font-size: .85rem;
  line-height: 1.5;
  font-family: "Montserrat";

}

.mat-cell {
  color: var(--dark-text);
}

.mat-header-cell {
  font-weight: 400;
  text-transform: uppercase;
  border-color: currentColor;
  color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  color: var(--primary-color);
  line-height: 1.2;
  word-break: break-word;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2rem;
}

.page-heading {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;

  h1 {
    margin-bottom: 0;
  }

  &__content {
    flex: 1 0 auto;

    & *:last-child {
      margin-bottom: 0;
    }
  }

  .mat-icon-button {
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;

    .mat-icon {
      width: 2.2rem;
      height: 2.2rem;
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
  }
}

h2 {
  font-size: 1.5rem;
}

.section-heading {
  align-items: flex-end;
  border-bottom: 1px solid var(--accent);
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 .5rem .5rem 0;

  h2 {
    line-height: 1;
    margin-bottom: 0;
  }

  button {
    color: var(--dark-text);
  }
}

.page-section {
  margin-bottom: 4rem;
}

.mat-dialog-container {
  color: var(--dark-text);
}

.modal {
  &__header {
    align-items: center;
    border-bottom: 1px solid var(--accent);
    margin-bottom: 1.5rem;
    padding: 0 0 .5rem;

    >h2,
    >div:first-child {
      flex: 1 0 auto;
      display: block;
    }

    h2 {
      line-height: 1;
      margin: 0 0 .2rem;
    }

    button {
      flex: 0 0 auto;
    }
  }

  &__sub-heading {
    color: var(--gray-text);
    font-size: .875rem;
  }

  &__content {
    margin-bottom: 1.5rem;

    h3 {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: .5rem;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: .5rem;
  }
}

.input-status .mat-form-field-infix {
  width: 120px;
}

.dark-backdrop {
  background-color: rgba(0, 0, 0, .1);
  backdrop-filter: blur(1px);
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 24px !important;
    line-height: 29px !important;
    margin-bottom: 30px !important;
  }

  h3 {
    font-size: 18px !important;
    line-height: 22px !important;
  }
}

button {
  border-radius: 44px !important;
}

h6 {
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px !important;
  text-transform: uppercase !important;
  color: var(--gray-text) !important;
  margin-top: 2em !important;
}

.snackbar-panel-component {
  background-color: transparent !important;
  box-shadow: none !important;
}

.checklist-items-stat {
  .checklist-stat {
    font-size: 24px;
  }
}

.text-muted {
  color: var(--gray-text) !important;
}

.text-green {
  color: #75b458 !important;
}

.text-red {
  color: #DD4B39 !important;
}

a.default-link-style {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: var(--primary-color);
  cursor: pointer;
}

.red-icon:before {
  content: '\25CF';
  color: #DD4B39;
  margin-right: 0.5em;
}

.green-icon:before {
  content: '\25CF';
  color: #00A65A;
  margin-right: 0.5em;
}

.gray-icon:before {
  content: '\25CF';
  color: #D5D5D5;
  margin-right: 0.5em;
}

.yellow-icon:before {
  content: '\25CF';
  color: #EFAE08;
  margin-right: 0.5em;
}

input[readonly] {
  color: grey;
}

mat-icon.active-menu-icon {
  color: var(--primary-color);
}

.list-group-item {
  border: 0 !important;
}

.ngx-file-drop-zone {
  border: 2px dotted var(--primary-color);
  border-radius: 0;
  height: 50px;
}

.ngx-file-drop__content {
  height: 50px !important;
}

.light-snack-bar {
  color: inherit;
  background-color: #fff;
}

@media screen and (max-width: 768px) {

  app-documents-list,
  app-company-list,
  app-location-list,
  app-dgc-list,
  app-item-list,
  app-dgc-files-list,
  app-training-list,
  app-protocol-list,
  app-user-list,
  app-admin-customer-management-list {
    overflow-x: scroll;
    width: 100%;
    display: block;
  }

}

td.mat-cell:first-of-type {
  padding-right: 24px;
}

@media screen and (max-width: 500px) {
  td.mat-cell {
    padding-right: 14px !important;
  }
}

app-location-list .mat-sort-header-content {
  text-align: left;
}

#iframeContainer {
  iframe {
    height: 100%;
  }
}

app-admin-certificate-list,
app-admin-employee-list,
app-admin-customer-list,
app-cm-dgm-list {
  mat-form-field {
    width: 100%;
  }
}

app-company-details app-notes-box {

  .alignment .mat-form-field-outline-start,
  .alignment .mat-form-field-outline-end {
    border-radius: 0px !important;
  }
}

app-admin-certificate-list,
app-company-overview,
app-user-overview,
app-admin-customer-list,
app-cm-dgm-list {
  .mat-form-field-outline-start {
    border-radius: 28px 0 0 28px !important;
    min-width: 28px !important;
    border-color: #004D87 !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0 28px 28px 0 !important;
    border-color: #004D87 !important;
  }
}

app-admin-certificate-list,
app-company-contract-form,
app-admin-customer-list,
app-cm-dgm-list {
  .mat-form-field-infix {
    padding: 0.5em 0;
    border-top: 0.44375em solid transparent;
  }
}

app-company-overview,
app-user-overview {
  .mat-form-field-infix {
    padding: .1em .5rem;
    border-top: 0.44375em solid transparent;
  }
}

app-company-contract-form {
  .mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0em;
    padding-right: 1em;
  }
}

.standalone-mat-label {
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 0.5em;
}

mat-label {
  white-space: normal;
}

// media query for tablets and phone
@media screen and (max-width: 768px) {
  .page-heading {
    flex-direction: column;
    align-items: start;
  }
}

.customer-type-mat-select {
  .mat-select-arrow {
    border: none;
  }
}

app-admin-accounting-list,
app-admin-capacity-list,
app-admin-customer-analytics,
app-admin-customer-progress-list,
app-admin-training-status-list,
app-admin-customer-management-list,
app-admin-customer-progress-list,
app-admin-email-log-list,
app-admin-annual-report-list,
app-admin-dgm-points-list {
  display: block;

  mat-table, table {
    overflow-x: scroll;
  }

  th.mat-header-cell,
  td.mat-cell {
    padding: 0px 10px;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }
}

app-admin-dgm-points-list-detail, app-dgm-points-list-content {
  th.mat-header-cell, td.mat-cell {
    border-left: 1px solid #e0e0e0;
    padding-left: 5px;
  }
}

app-admin-accounting-list,
app-admin-capacity-list,
app-admin-customer-analytics,
app-admin-customer-progress-lis,
app-admin-training-status-list,
app-admin-customer-management-list,
app-admin-customer-progress-list,
app-admin-annual-report-list,
app-admin-dgm-points-list {

  .mat-cell,
  .mat-header-cell {
    padding: 0px 10px;
    line-break: unset;
    vertical-align: middle;
  }
}

.table-column-wrapper {
  width: 250px;
  white-space: pre-line;
  word-break: break-all;
}

.table-column-wrapper-160 {
  width: 160px;
  white-space: pre-line;
  word-break: break-all;
}

app-admin-training-status-list {
  .mat-table {
    display: table;
    width: 100%;
  }
}

app-list-item-manager,
app-admin-training-content-component,
app-training-topics,
app-training-subtopics,
app-admin-additional-services,
app-admin-annual-report-actions {
  .mat-expansion-panel-header {
    background: #F1F1F1 !important;
    margin-bottom: 10px;
  }
}

app-list-item-manager {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0 1em 0;
  }
}

.strong {
  font-weight: bold;
}

app-admin-dgm-points-list {
  .mat-sort-header-content {
    text-align: left;
  }
}

.text-xs {
  font-size: 12px;
  color: white;
}

app-protocol-form-master-nested,
app-admin-protocol-overview {
  .mat-expansion-panel-body {
    padding: 0
  }
}

app-protocol-form-master-nested {
  .card-content-container {
    display: flex;
  }
}

app-protocol-item-form {
  .mat-radio-label {
    display: flex;
    justify-content: space-between;
  }

  .mat-radio-label-content {
    flex-grow: 1;
  }

  .radio-option.mat-radio-checked {
    border-color: var(--accent-color);
    border-width: 2px;
  }
}

// https://stackoverflow.com/a/57759753
// https://creativecommons.org/licenses/by-sa/4.0/
.small-icon-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;


  &>*[role=img] {
    width: 16px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 24px !important;
    height: 24px !important;
  }
}

button.small-icon-button {
  margin-left: 0.3em;
  margin-right: 0.3em;
  margin-top: 0.3em;
}

app-protocol-report {

  .mat-input-element[type=date]::after,
  .mat-input-element[type=time]::after {
    content: none;
  }

  .mat-input-element[type=date],
  .mat-input-element[type=time] {
    border: none;
  }
}

.fixed-th-100 {
  width: 100px;
}

.fixed-th-150 {
  width: 150px;
}

.fixed-th-200 {
  width: 200px;
}

.fixed-th-400 {
  width: 400px;
}

.fixed-th-250 {
  width: 250px;
}

.fixed-th-300 {
  width: 300px;
}

.fixed-th-350 {
  width: 350px;
}

.fixed-th-50 {
  width: 50px;
}

app-documents-list,
app-protocol-list,
app-company-certificate-list,
app-company-annual-report-list,
app-user-files-list,
app-training-list {
  .mat-sort-header-content {
    text-align: left;
  }
}

app-admin-protocol-overview, app-admin-library {
  .mat-tab-body-content {
    transform: none;
    overflow-x: hidden;
    width: 100%;
    padding: 0.2em;
  }
}

app-protocol-report {
  .horizontal-scroll {
    overflow-y: hidden;
  }
}

app-admin-protocol-overview, app-admin-library {
  .mat-list-base {
    padding-top: 0;
  }
}

app-admin-library {
  .mat-expansion-panel-body {
    padding: 0;
  }
}
.hover:hover {
  background-color: #f1f1f1;
}

app-protocol-select-form-field {

  /* Style for the dragged item */
  .cdk-drag-preview {
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  /* Style for the placeholder during dragging */
  .cdk-drag-placeholder {
    opacity: 0.5;
    border: 2px dashed #75B458;
    /* Adjusted color */
    background-color: rgba(0, 0, 0, 0.1);
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  /* Style for the item being dragged over */
  .cdk-drop-list-dragging .cdk-drop-list-placeholder {
    background-color: rgba(117, 180, 88, 0.2);
    /* Adjusted color */
  }

  /* Style for the item being dropped */
  .cdk-drop-list-dragging .cdk-drag {
    border: 2px dashed #75B458;
    /* Adjusted color */
    background-color: rgba(117, 180, 88, 0.1);
    /* Adjusted color */
  }
}

.date-input-container,
.time-input-container {
  display: inline-block;
  position: relative;
}

.date-input-container input[type='date'],
.time-input-container input[type='time'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.contract-price-table {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }

  td {
    align-content: center !important;
  }
}

.last-edited {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: grey;
}

app-admin-payment-period {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-select-trigger {
  height: auto;
}

app-protocol-item-form {
  mat-form-field {
    width: 100%;
  }

  .slide-option {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    transition: border-color 0.3s ease, border-width 0.3s ease;

    &:has(.mat-slide-toggle.mat-checked) {
      border-color: var(--accent-color);
      border-width: 1px;
    }
  }

  .mat-slide-toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .mat-slide-toggle-content {
    flex-grow: 1;
  }

  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: var(--accent-color);
    }

    .mat-slide-toggle-thumb {
      background-color: var(--accent-color);
    }
  }

  .grey-button {
    color: grey;
    border-color: grey;
  }
}

.mat-checkbox-label {
  text-wrap: auto;
}

app-location-un-numbers {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding: 0 !important;
  }
}

app-company-contract-list {
  .svg-mat-icons {
    line-height: 0 !important;
    &.mat-icon-button i, &.mat-icon-button .mat-icon {
      line-height: 0 !important;
    }
  }
}
